import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import NotFoundPage from "./pages/404";




const pageRoutes = [
  { path: "*", element: <NotFoundPage /> },
  { path: "", element: <Main /> },
];

const AppRouter = () => {
  return (
    <Routes>
      {pageRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
};
export default AppRouter;
