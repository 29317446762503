import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme.js";
import "./App.css";
import { CustomUIProvider } from "./context/CustomIUProvider.jsx";

import AppRouter from "./Routes.jsx";



const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <CustomUIProvider>
            <BrowserRouter>
              <CssBaseline />
              <AppRouter />
            </BrowserRouter>
        </CustomUIProvider>
      </ThemeProvider>

  );
};

export default App;
