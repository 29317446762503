import { Button, Typography } from "@mui/material";
import TopMenu from "../components/Layout/TopMenu";
import Box from "@mui/material/Box"; // Додаємо Box з @mui/material
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Main() {
  const handleButtonClick = () => {
    window.location.href = "https://crm.logistic-hub.com"; // Заміни на свій субдомен
  };
  return (
    <>
      <TopMenu />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height="90vh" // Забезпечує, що контейнер займає всю висоту екрану
      >
        <Grid container>
          <Grid xs={12}>
            <Typography variant="h1">Main page</Typography>
          </Grid >
          <Grid xs={12}>
          <Button variant="contained" sx={{ mt: 4, px:4}} onClick={handleButtonClick}>
            Go to CRM
          </Button>            
          </Grid>

        </Grid>

      </Box>
    </>
  );
}

export default Main;
